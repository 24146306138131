'use client';

import {
	AccountInfo,
	AuthenticationResult,
	BrowserAuthError,
	IPublicClientApplication,
	InteractionRequiredAuthError,
	InteractionStatus,
	InteractionType,
	PopupRequest,
	RedirectRequest,
	SilentRequest,
} from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { isAfter } from '@dte/otw/azure-functions/src/common/util/dates/compare/isAfter';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { addMinutes, minutesToMilliseconds, secondsToMilliseconds } from 'date-fns';
import { useCallback, useEffect, useMemo } from 'react';
import useSWR, { SWRConfiguration } from 'swr';
import { getLoginMethod, useRedirectRequest } from '../../common/util/employeeAuthConfig';
import { logError, logException, logInfo, logWarning } from '../../common/util/logging/writeLog';
import { useAppInsights } from '../../components/AppInsightsWrapper/AppInsightsWrapper';
import { getTokenExpiration } from './isGoodToken';
import { useMsalAccount } from './useMsalAccount';

function tokenExpiresSoon(token: string, minutes = 10): boolean {
	const expiryDate = getTokenExpiration(token);
	if (!expiryDate) {
		return true;
	}

	// Token expires within the next X minutes
	const thresholdDate = addMinutes(new Date(), minutes);
	if (isAfter(thresholdDate, expiryDate)) {
		return true;
	}

	return false;
}

async function handleLoginError(
	appInsights: ReactPlugin,
	instance: IPublicClientApplication,
	account: AccountInfo,
	error: Error,
	redirectRequest: RedirectRequest | PopupRequest,
	forceManualLogin?: boolean,
): Promise<AuthenticationResult> {
	let retryError = false;
	let forceManualLoginOnRetry = forceManualLogin;

	if (error instanceof InteractionRequiredAuthError) {
		logWarning(appInsights, 'Interaction required - do a manual login');
		retryError = true;
		forceManualLoginOnRetry = true;
	}

	if (error instanceof BrowserAuthError) {
		if (error?.errorMessage?.includes('timeout')) {
			logWarning(appInsights, 'Login timed out, retry');
			retryError = true;
		}
	}

	// If we weren't already trying a manual login, go ahead and try again
	if (retryError) {
		return fetchAuthenticationResult(
			appInsights,
			instance,
			account,
			redirectRequest,
			true,
			forceManualLoginOnRetry,
		);
	}

	return undefined;
}

export async function manualLogin(
	appInsights: ReactPlugin,
	instance: IPublicClientApplication,
	redirectRequest: RedirectRequest | PopupRequest,
): Promise<AuthenticationResult> {
	const loginMethod = getLoginMethod();

	logInfo(appInsights, `Attempt to acquire token manually, method: [ ${loginMethod} ]`);

	if (loginMethod === InteractionType.Redirect) {
		await instance.acquireTokenRedirect(redirectRequest);
		return undefined;
	}

	if (loginMethod === InteractionType.Popup) {
		return instance.acquireTokenPopup(redirectRequest);
	}

	return undefined;
}

async function handleLogin(
	appInsights: ReactPlugin,
	instance: IPublicClientApplication,
	tokenRequest: SilentRequest,
	redirectRequest: RedirectRequest | PopupRequest,
	forceManualLogin?: boolean,
): Promise<AuthenticationResult> {
	let result: AuthenticationResult;

	if (forceManualLogin) {
		// Use a manual process like popup or redirect to get the token
		result = await manualLogin(appInsights, instance, redirectRequest);
	} else {
		result = await instance.acquireTokenSilent(tokenRequest);
	}

	return result;
}

// TODO: logic is somewhat convoluted because MSAL doesn't properly check for expiry of id tokens
// See:
// https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/4206
export async function fetchAuthenticationResult(
	appInsights: ReactPlugin,
	instance: IPublicClientApplication,
	account: AccountInfo,
	redirectRequest: RedirectRequest | PopupRequest,
	forceRefresh?: boolean,
	forceManualLogin?: boolean,
): Promise<AuthenticationResult> {
	let useForceRefresh = forceRefresh;

	// Set refresh initially based on the age of the current cached
	let needsRefresh = tokenExpiresSoon(account?.idToken);
	if (needsRefresh) {
		useForceRefresh = true;
	}

	const tokenRequest: SilentRequest = {
		scopes: ['openid'],
		account: account,
		forceRefresh: useForceRefresh,
	};

	let result: AuthenticationResult;

	try {
		result = await handleLogin(appInsights, instance, tokenRequest, redirectRequest, forceManualLogin);
	} catch (error) {
		// If we're not already trying a manual login
		if (!forceManualLogin) {
			return handleLoginError(appInsights, instance, account, error, redirectRequest, forceManualLogin);
		}
		logError(appInsights, 'Manual attempt to retrieve token failed due to error');
		logException(appInsights, error);
	}

	// Make sure the result contains a token that isn't about to expire
	needsRefresh = tokenExpiresSoon(result?.idToken);
	if (needsRefresh) {
		// Result isn't valid
		result = undefined;

		// Fall back on force refresh if needed
		if (!useForceRefresh) {
			logWarning(appInsights, 'Try again to acquire token, this time forcing a manual login');
			return fetchAuthenticationResult(appInsights, instance, account, redirectRequest, true, forceManualLogin);
		}

		// Fall back on manual login next
		if (!forceManualLogin) {
			logWarning(appInsights, 'Manual attempt to retrieve token failed, token was undefined');
			return fetchAuthenticationResult(appInsights, instance, account, redirectRequest, true, true);
		}

		logError(appInsights, 'Unable to retrive token from either force refresh or manual login');
	}

	return result;
}

const swrOptions: SWRConfiguration = {
	// Don't try to grab again on focus for at least 5 minutes
	focusThrottleInterval: minutesToMilliseconds(5),
	// If multiple requests come in within the same 15 seconds, make sure to group them together
	dedupingInterval: secondsToMilliseconds(15),
	// How long to trigger slow loading indicator
	loadingTimeout: secondsToMilliseconds(3),

	suspense: false,
	fallbackData: undefined,
	// TODO: maybe we can use suspense with React 19?
};

export function useAuthenticationResult(): [AuthenticationResult, () => Promise<AuthenticationResult>] {
	const appInsights = useAppInsights();

	// Attemp silent login first
	const { instance, inProgress } = useMsal();
	const account = useMsalAccount();

	const redirectRequest = useRedirectRequest();

	const readyToFetch = useMemo(() => {
		if (inProgress !== InteractionStatus.None) {
			// Login in progress
			return false;
		}
		// App insights isn't setup yet
		if (!appInsights) {
			return false;
		}
		// Redirect request isn't setup yet
		if (!redirectRequest) {
			return false;
		}
		// No account yet
		if (!account) {
			return false;
		}
		return true;
	}, [appInsights, account, inProgress, redirectRequest]);

	const fetcher = useCallback(async () => {
		return fetchAuthenticationResult(appInsights, instance, account, redirectRequest);
	}, [appInsights, instance, account, redirectRequest]);

	// Unique key to identify this input element
	const key = readyToFetch ? 'msalAuth' : undefined;
	const { data: authenticationResult, mutate, error } = useSWR<AuthenticationResult>(key, fetcher, swrOptions);

	useEffect(() => {
		if (error) {
			logError(appInsights, 'Error fetching authentication result');
			logException(appInsights, error);
		}
	}, [appInsights, error]);

	return [authenticationResult, mutate];
}
