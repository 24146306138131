'use client';

import { safeParseURL } from '@dte/otw/azure-functions/src/common/util/network/url/getURL';
import { lowerCaseValue } from '@dte/otw/azure-functions/src/common/util/strings/lowerCaseValue';
import { ITelemetryItem, RemoteDependencyData } from '@microsoft/applicationinsights-web';

// Things we don't need to worry about logging successes for
const hostnames = [
	// Don't fill up logs with requests for map tiles
	'arcgis.com',
	// We don't need to know that App Insights was able to talk to itself
	'monitor.azure.com',
	// Don't need to monitor successful logging for fullstory
	'fullstory.com',
	// Skip successful login requests
	'login.microsoftonline.com',
	// Pageview requests will cover these
	'outageadmin.sites.dteenergy.com',
];

function getDependencyData(envelope: ITelemetryItem): RemoteDependencyData {
	const baseType = envelope?.baseType;
	if (baseType !== 'RemoteDependencyData') {
		return undefined;
	}

	return envelope?.baseData as RemoteDependencyData;
}

function getHostname(url: string | URL): string {
	const urlString = safeParseURL(url);
	if (!urlString) {
		return undefined;
	}

	const urlObject = new URL(urlString);
	return lowerCaseValue(urlObject?.hostname);
}

function shouldFilterDependencyUrl(url: string): boolean {
	const dependencyHostname = getHostname(url);
	if (!dependencyHostname) {
		return false;
	}

	for (const hostname of hostnames) {
		if (dependencyHostname.includes(hostname)) {
			return true;
		}
	}

	return false;
}

export function filterDependencyTracking(envelope: ITelemetryItem): boolean {
	// Skip some dependencies
	const dependencyData = getDependencyData(envelope);
	if (dependencyData) {
		// Don't skip calls that aren't dependencies or that have failed
		if (!dependencyData?.success) {
			return true;
		}

		const url = dependencyData?.target;
		if (shouldFilterDependencyUrl(url)) {
			return false;
		}
	}

	// Allow all other telemetry items
	return true;
}
